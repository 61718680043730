import { ref } from 'vue'
import { defineStore } from 'pinia'

import type { Customer } from '@dreamshaper/ds-interface'

export const useCustomerStore = defineStore('customerStore', () => {
  const customers = ref<Customer[] | undefined | null>()

  const contextInEditing = ref<boolean>()
  const isOpenCreateReportContext = ref<boolean>(false)
  const isOpenEditReportContext = ref<boolean>(false)

  const periodInEditing = ref<boolean>()
  const isOpenCreateReportPeriod = ref<boolean>(false)
  const isOpenEditReportPeriod = ref<boolean>(false)

  return {
    customers,
    contextInEditing,
    isOpenCreateReportContext,
    isOpenEditReportContext,
    periodInEditing,
    isOpenCreateReportPeriod,
    isOpenEditReportPeriod,
  }
})
